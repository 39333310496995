.checkbox-group {
    padding: 0px 10px;
}

.checkbox-group__input {
    margin: 0px 10px 0px 10px;
}

.checkbox-group__label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
}

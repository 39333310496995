/**
 * Please file all font-face values in here
 */

 @font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}
@font-face {
 font-family: "Montserrat";
 src: url("./fonts/Montserrat-Medium.ttf");
 font-weight: 500;
}
@font-face {
 font-family: "Montserrat";
 src: url("./fonts/Montserrat-SemiBold.ttf");
 font-weight: 600;
}
@font-face {
 font-family: "Montserrat";
 src: url("./fonts/Montserrat-Bold.ttf");
 font-weight: 700;
}

@font-face {
 font-family: "IBMPlexSansArabic";
 src: url("./fonts/IBMPlexSansArabic-Regular.ttf");
 font-weight: 400;
}
@font-face {
font-family: "IBMPlexSansArabic";
src: url("./fonts/IBMPlexSansArabic-Medium.ttf");
font-weight: 500;
}
@font-face {
font-family: "IBMPlexSansArabic";
src: url("./fonts/IBMPlexSansArabic-SemiBold.ttf");
font-weight: 600;
}
@font-face {
font-family: "IBMPlexSansArabic";
src: url("./fonts/IBMPlexSansArabic-Bold.ttf");
font-weight: 700;
}


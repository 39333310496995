@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?lraabh');
  src:  url('./icomoon.eot?lraabh#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?lraabh') format('truetype'),
    url('./icomoon.woff?lraabh') format('woff'),
    url('./icomoon.svg?lraabh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_left_outline:before {
  content: "\e92e";
}
.icon-arrow_right_outline:before {
  content: "\e92f";
}
.icon-star_empty:before {
  content: "\e92b";
}
.icon-star_filled:before {
  content: "\e92c";
}
.icon-star_half:before {
  content: "\e92d";
}
.icon-hide_eye:before {
  content: "\e92a";
}
.icon-bid:before {
  content: "\e929";
}
.icon-item_secured:before {
  content: "\e921";
}
.icon-item_condition:before {
  content: "\e922";
}
.icon-item_viewed:before {
  content: "\e923";
}
.icon-item_location:before {
  content: "\e924";
}
.icon-search:before {
  content: "\e925";
}
.icon-item_pickup:before {
  content: "\e926";
}
.icon-item_delivery:before {
  content: "\e927";
}
.icon-item_status:before {
  content: "\e928";
}
.icon-Timer:before {
  content: "\e900";
}
.icon-Verified:before {
  content: "\e901";
}
.icon-Sent:before {
  content: "\e902";
}
.icon-Bid:before {
  content: "\e903";
}
.icon-manual:before {
  content: "\e904";
}
.icon-sold_out:before {
  content: "\e905";
}
.icon-mins_circle:before {
  content: "\e906";
}
.icon-add_circle:before {
  content: "\e907";
}
.icon-mark:before {
  content: "\e908";
}
.icon-rotate:before {
  content: "\e909";
}
.icon-attach:before {
  content: "\e90a";
}
.icon-notification:before {
  content: "\e90b";
}
.icon-add-square:before {
  content: "\e90c";
}
.icon-mins-square:before {
  content: "\e90d";
}
.icon-house:before {
  content: "\e90e";
}
.icon-heart-filled:before {
  content: "\e90f";
}
.icon-profile:before {
  content: "\e910";
}
.icon-arrow-right:before {
  content: "\e911";
}
.icon-arrow-bottom:before {
  content: "\e912";
}
.icon-bag:before {
  content: "\e913";
}
.icon-heart:before {
  content: "\e914";
}
.icon-arrow-top:before {
  content: "\e915";
}
.icon-language:before {
  content: "\e916";
}
.icon-eye:before {
  content: "\e917";
}
.icon-dashboard:before {
  content: "\e918";
}
.icon-postsimilar:before {
  content: "\e919";
}
.icon-secure:before {
  content: "\e91a";
}
.icon-share:before {
  content: "\e91b";
}
.icon-briefcase:before {
  content: "\e91c";
}
.icon-arrow-left:before {
  content: "\e91d";
}
.icon-arrow-down:before {
  content: "\e91e";
}
.icon-add:before {
  content: "\e91f";
}
.icon-cancel:before {
  content: "\e920";
}
.icon-play4:before {
  content: "\ed33";
}
.icon-google-plus:before {
  content: "\f0b1";
}
.icon-facebook:before {
  content: "\f0b6";
}
.icon-instagram:before {
  content: "\f0b8";
}
.icon-whatsapp:before {
  content: "\f0b9";
}
.icon-twitter:before {
  content: "\f0bc";
}
.icon-youtube:before {
  content: "\f0c3";
}
.icon-linkedin:before {
  content: "\f0f0";
}

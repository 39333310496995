.auth-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 138px 375px 30px 375px;
  color: white;
  font-size: $fs-12;
  &__right-reserved {
    &__mazadat-inc {
      font-weight: 600;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    a {
      color: white;
    }
    a:hover {
      color: $color-blue-200 !important;
      transition: 200ms !important;
    }

    &__contactus,
    &__terms {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 559px) {
  .auth-footer {
    &__right-reserved {
      text-align: center;
      p {
        font-size: $fs-12 !important;
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  .auth-footer {
    display: block;
    padding: 30px 0px;
    &__right-reserved {
      text-align: center;
    }
    &__links {
      display: none;
    }
  }
}

@media only screen and (min-width: 861px) and (max-width: 1024px) {
  .auth-footer {
    padding: 30px 20px 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1240px) {
  .auth-footer {
    padding: 40px 30px 30px 30px;
  }
}

@media only screen and (min-width: 1241px) and (max-width: 1439px) {
  .auth-footer {
    padding: 40px 80px 30px 80px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1500px) {
  .auth-footer {
    padding: 50px 120px 30px 120px;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
  .auth-footer {
    padding: 50px 150px 30px 150px;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .auth-footer {
    padding: 60px 200px 30px 200px;
  }
}
@media only screen and (min-width: 1701px) and (max-width: 1770px) {
  .auth-footer {
    padding: 80px 250px 30px 250px;
  }
}
@media only screen and (min-width: 1771px) and (max-width: 1870px) {
  .auth-footer {
    padding: 100px 320px 30px 320px;
  }
}

body[dir="rtl"] {
  .auth-footer {
    &__links {
      &__terms {
        margin-right: 10px;
      }
      &__privacy-policy {
        margin-right: 10px;
      }
    }
  }
}

.authHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 375px 93px 375px;
  &__black-logo {
    width: 240px;
  }

  &__auth-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__back-button {
      margin-right: 20px;
      button {
        background-color: transparent !important;
        color: white;
        font-size: $fs-12;
        font-weight: 600;
        display: flex;
        align-items: center;
        border: none;
        &:hover {
          cursor: pointer;
          color: $color-blue-200 !important;
          transition: 200ms;
        }
      }
      .icon-arrow-left {
        font-size: $fs-18;
      }
    }
    &__sign-up-button {
      border-color: $color-blue-200 !important;
      color: $color-blue-200 !important;
      background-color: transparent;
      font-weight:600;
      padding: 7px 19.2px;
      font-size:$fs-12;
      min-width: 90px;
        }
        &__sign-up-button:hover {
          background-color:$color-grey-1800;
          transition: 200ms;
          opacity: unset;
          border: 1px solid transparent !important;
        }
    &__language-button {
      a {
        margin-left: 20px;
        padding: 0;
        font-weight: 500;
      }
      .dropdown-toggle {
        color: white;
      }
      .dropdown-item {
        margin-left: 0px;
      }
    }
  }
}
body[dir='rtl'] {
  .authHeaderContainer {
    &__auth-buttons {
      &__back-button {
        margin-left: 20px;
        margin-right: 0px;
        .icon-arrow-right {
          font-size: $fs-18;
        }
      }
      &__language-button {
        a {
          margin-left: 0px;
          margin-right: 20px;
        }
        .dropdown-item {
          margin-right: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1870px) {
  .authHeaderContainer {
    padding: 30px 320px 100px 320px;
  }
}
@media only screen and (max-width: 1770px) {
  .authHeaderContainer {
    padding: 30px 250px 100px 250px;
  }
}
@media only screen and (max-width: 1700px) {
  .authHeaderContainer {
    padding: 30px 200px 100px 200px;
  }
}
@media only screen and (max-width: 1600px) {
  .authHeaderContainer {
    padding: 30px 150px 100px 150px;
  }
}
@media only screen and (max-width: 1500px) {
  .authHeaderContainer {
    padding: 30px 120px 100px 120px;
  }
}
@media only screen and (min-width: 1241px) and (max-width: 1439px) {
  .authHeaderContainer {
    padding: 30px 80px 100px 80px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1240px) {
  .authHeaderContainer {
    padding: 30px 30px 100px 30px;
  }
}


@media only screen and (min-width: 861px) and (max-width: 1024px) {
  .authHeaderContainer {
    padding: 30px 20px 75px 20px;
  }
}



@media only screen and (max-width: 860px) {
  .authHeaderContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: unset;
    padding: 20px 20px 0px;
    &__upper-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      &__mobile-back-button {
        span {
          background-color: transparent;
          font-size:$fs-24;
          color:$color-white-true !important;
        }
      }
      &__language-button {
        .nav-item {
          .nav-link {
            padding: 0%;
            color: $color-white-true;
          }
        }
      }
      &__black-logo {
        img {
          width: 165px;
        }
      }
    }
  }
 
}
@media only screen and(max-width : 559px) {
  .authHeaderContainer__black-logo {
    width: 165px;
    margin-bottom: 20px;
  }
}

.discount-container{
  padding: 0.75rem 2.75rem;
}
.discount-buttons-container{
  background-color: rgb(144, 209, 250);
  color: white;
  border-radius: 15px;
  &__icon{
    height: 0.7rem;
    margin-right: 0.5rem;
  }
  button{
    background-color: transparent;
    border: none;
    padding: 0.5rem 1.5rem;
    width: 50%;
  }
  .active{
    background-color: $color-blue-400;
    border-radius: 15px;
    color: white;
  }
}
.discount-text{
  margin-top: 20px;
  border-radius: 15px;
  border : 1px solid grey;
}
.discount-text:focus{
  border-color: $color-blue-400 !important;
}
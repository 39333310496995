/* Remove margins and padding. */

body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
    margin: 0;
    padding: 0;
}

/* Remove trailing margins from nested lists. */

li > {
    ol,
    ul {
        margin-bottom: 0;
    }
}
//solution for showing iframe in the body,
//TODO : maybe another solution later

body > iframe{
    display: none !important;
}
/**
 * Remove the gap between audio, canvas, iFrames, images, videos and the bottom
 * of their containers: https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/**
 * 1. Remove default border on fieldSets.
 * 2. Reset Chrome and Firefox behavior which sets a `min-width: min-content;`
 *    on fieldSets.
 */

fieldset {
    border: 0; /* [1] */
    min-width: 0; /* [2] */
}

/**
 * Remove default table spacing.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/*
    solution for zooming in IOS devices
*/
@media (max-width: 860px) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea {
    font-size: $fs-16 !important;
    box-shadow: none !important;
    outline: none !important;
    }
}
.not-found-footer{
    background-color: $color-gray-600;
    padding: 5rem 20rem;
    text-align: center;
    &_container{
        width: 692px;
        align-items: center;
        margin: auto;
    }
    &__download-app{
        margin-bottom: 1rem;
        img{
            height: 40px;
        }
    }
    &__social{
        font-size: 30px;
        margin: 0 1rem;
        color: $color-gray-200;
    }
    &__link{
        text-align: justify;
        color: $color-gray-200;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
    }
    &__rights{
        p{
            font-size: 14px;
            font-stretch: normal;
            line-height: 2.21;
            letter-spacing: normal;
            margin-bottom: 0;
            color: #133;
            font-weight: 300;
            u{
                text-decoration: underline;
                font-weight: 700;
                font-style: italic;
                color: #1bb0b8;
                margin: 0px 5px 0px 5px;
            }
        }
        small{
            font-size: 10px;
            letter-spacing: normal;
            color: #0f3333;
        }
    }
}
@media only screen and (max-width: 1024px) {
    .not-found-footer{
        padding: 2rem 5rem;
        &_container{
            width: auto;
        }
    }
  }
@media only screen and (max-width: 484px) {
    .not-found-footer{
        padding: 2rem;
            &_container{
                width: auto;
            }
    }
  }
@media only screen and (max-width: 320px) {
    .not-found-footer{
        padding: 1rem;

            &__download-app{
                img{
                    height: 30px;
                }
            }
    }
  }
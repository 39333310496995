.slogan-content {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  &__slogans {
    display: flex;
    flex-direction: column;
    color: white;
    margin-left: 30px;
    max-width: 452px;
    span {
      font-size: $fs-18;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}

body[dir="rtl"] {
  .slogan-content {
    &__slogans {
      margin-right: 30px;
      margin-left: 0px;
    }
  }
}

@media only screen and(max-width : 1240px) {
  .slogan-content {
    &__slogans {
      p {
        max-width: 385px;
      }
    }
  }
}

@media only screen and (min-width: 861px) and (max-width: 1024px) {
  .slogan-content {
    &__slogans {
      max-width: 80%;
      p {
        max-width: unset;
      }
    }
  }
}

@media only screen and(max-width : 860px) {
  .slogan-content {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    &__slogans {
      max-width: unset;
      h4 {
        font-size: $fs-21;
        line-height: 1.5;
      }
      p {
        max-width: unset;
      }
    }
    img {
      max-height: 60px !important;
      margin-bottom: 7px;
    }
  }
}

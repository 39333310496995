.custom-switch{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
    &__label{
        font-size: $fs-14;
        font-weight:600;
    }
    .react-switch-handle{
        top: -6px !important;
        height: 21px !important;
        width: 21px !important;
        border: 1px solid $color-grey-1200 !important; 
        box-shadow: none !important;
        outline: none;
        
    }
    .react-switch-bg{
        background: $color-grey-1000 !important;
    }
}
.checked--true{
    .react-switch-handle{
        background-color: $color-secondary !important;
        box-shadow: none !important;
        border-color: $color-secondary !important;
        left: -10px;
        transition: none!important;
    }
    .react-switch-bg{
    background: $color-secondary  !important;
    opacity: 0.4;
    }
}
.checked--false{
    .react-switch-handle{
        transition: none !important;
    }
}
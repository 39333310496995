/* default mood */
/* light mood */
.text-field {
  position: relative;
  min-width: 60px;
  min-height: 86.5px;
  &__underline {
    display: flex;
    justify-content: space-between;
    &__strength-password {
      font-size: $fs-10;
      padding: 3px 10px;
    }
  }
  ::placeholder {
    color: $color-gray-200;
  }

  fieldset {
    padding-left: 14px !important;
    border: 1px solid $color-grey-1000 !important;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
  }
  .legend-placeholder {
    font-size: $fs-11;
    font-weight: 600;
    text-align: left !important;
    width: auto;
    padding: 0 3px;
    border-bottom: none;
    color: $color-gray-100;
    float: none;
    text-transform: capitalize;
    margin-bottom: 0px !important;
  }
  &__input::placeholder {
    color: $color-grey-1300;
  }

  &__input {
    width: 100%;
    display: flex;
    text-indent: 3px;
    padding: 8px 14px 8px 0px;
    border: none;
    background: transparent;
    font-size: $fs-14;
    color: $color-grey-1400;
    -webkit-tap-highlight-color: transparent;
  }
  &__input:focus {
    outline: none;
  }
  .PhoneInput {
    padding: 4px 5px 12px;
    input[type~='tel'] {
      background-color: transparent;
      border: none;
      font-size: $fs-14;
      color: $color-grey-1400;
      -webkit-tap-highlight-color: transparent;
      line-height: 1.8;
    }
    input[type~='tel']:focus-within {
      outline: none;
    }
    .PhoneInputCountryIcon--border {
     box-shadow: 0px 0px 3px 2px #c5c3c34d;
    }
    .PhoneInputCountrySelect:focus
      + .PhoneInputCountryIcon
      + .PhoneInputCountrySelectArrow {
      opacity: 1;
      color: $color-gray-200;
    }
    .PhoneInputCountrySelectArrow {
      border-style: solid;
      border-color: var(--PhoneInputCountrySelectArrow-color);
      border-top-width: 0;
      border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      border-left-width: 0;
      border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      }
  }

  .fieldset-error,
  .fieldset-error:focus-within {
    border-color: $color-tomato-100 !important;
    transition: 200ms;
  }
  fieldset:focus-within {
    border-color: $color-secondary !important;
    transition: 200ms;
  }

  &__end-label {
    padding: 4px 16px 5px 12px;
    font-size: $fs-10;
    color: $color-grey-1500;
    text-transform: capitalize;
  }
  &__end-button {
    padding: 4px 16px 12px 12px;
    font-size: $fs-12;
    color: $color-secondary;
    text-transform: capitalize;
    font-weight: 600;
    background-color: transparent;
    border: none;
  }
  &__end-button:disabled,
  &__end-button:disabled:hover {
    color: $color-grey-1500;
    background-color: unset !important;
    border: none;
  }
  &__toggle-password {
    padding-right: 12px;
    font-size: $fs-16;
    color: $color-grey-1100;
    background-color: transparent;
    border: none;
    width: unset;
    .password-eye {
      font-size: 21px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: $color-gray-200;
    -webkit-box-shadow: none;
    transition: 200ms;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
  }
}
.text-field--textarea {
  min-height: 168px;
}
/* dark mood */
.text-field--dark {
  ::placeholder {
    color: white;
  }
  .legend-placeholder {
    color: white;
  }
  .text-field__input {
    color: white;
  }
  fieldset {
    padding-left: 14px !important;
    border: 1px solid white;
  }
  .text-field__end-label {
    padding: 8px 16px 9px 12px;
    font-size: $fs-10;
    color: $color-grey-1600;
    text-transform: capitalize;
  }
}

body[dir='rtl'] {
  .text-field {
    fieldset {
      padding-right: 14px !important;
      padding-left: 0 !important;
    }
    &__input {
      padding: 8px 0px 8px 14px;
    }
    &__toggle-password {
      padding-left: 12px;
      padding-right: 0px;
    }
    .legend-placeholder {
      text-align: right !important;
    }
    .PhoneInput {
      input[type~='tel'] {
        direction: ltr;
        text-align: end;
      }
      .PhoneInputCountry {
        .PhoneInputCountrySelectArrow {
          margin-right: 5px;
        }
      }
    }
  }
}

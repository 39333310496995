.inquiries {
    display: grid;
    width: 100%;
    background: $color-gray-600;
    grid-template-columns: 350px auto;
    overflow-x: hidden;
}

.inquiries__chat-list {
    background: $color-white-true;
    grid-row-start: 1;
    grid-row-end: span 3;
    border-right: solid 1px $color-gray-600;
}

.inquiries__chat-screen {
    background: $color-white-true;
    grid-row-start: 1;
    grid-row-end: span 3;
}

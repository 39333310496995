.input-group-otp {
  height: 113px;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

}
.input-otp {
  width: 370px;
  height: 82px;
  border: solid 1px $color-grey-1000;
  border-radius: 5px;
  line-height: 0.45;
  letter-spacing: 24px;
  font-size:$fs-32;
  font-weight: 600;
  color: #091f1f;
  line-height: 1.13;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
}
.input-otp::placeholder{
  position: relative;
  top: 10px;
  font-size: 32px;
  color:$color-grey-1500;
}
.input-otp-error,
.input-otp-error:focus {
  border-color: $color-tomato-100 !important;
  box-shadow: none !important;
}
.input-otp:focus,
.input-otp:focus-visible {
  border-color: $color-secondary !important;
  box-shadow: none !important;
  transition: 200ms;
  outline: none !important;
}

@media only screen and (max-width : 912px){
  .input-otp{
    margin: auto;
  }
}
@media only screen and (max-width : 560px){
  .input-otp{
    height: 71px;
  }
  .input-group-otp {
    height: 100px;
  }
}

@media only screen and (max-width : 449px){
  .input-otp{
    width: 300px;
  }
  .input-group-otp {
   max-width: 300px;
   .otp-error-padding{
    padding: 3px 2px;
  }
  }
}

body[dir='rtl'] {
  .input-otp {
    direction: ltr;
  }
}
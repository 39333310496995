.spinner{
    height: 100vh;
    position: fixed;
    background: white;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    text-align: center;
    scroll-behavior: unset;
}
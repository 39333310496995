.table{
    &__thumbnail{
        height: 50px;
        width: 50px;
        border-radius: 5px;
        text-align: center;
    }
    td{
      color : $color-gray-400;
      position: relative;
      transition: all 0.5s ease;
      font-size: 13px;
    }
    &__show-more{
        display: none;
        border-radius: 5px;
        padding:0;
        color: $color-black-true;
        background-color: $color-white-true;
        transition: all 1s ease;
        text-align: left;
        li{
            padding : 0.25rem 0.75rem;
            transition: all 0.5s ease;
        }
        li:hover{
            background-color : $color-gray-900;
        }
    }
}
main {
  min-height: 100vh;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.display-inline-block {
  display: inline-block !important;
}
.display-block {
  display: block !important;
}
.display-none {
  display: none !important;
}
.paper {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(214, 213, 213, 0.801);
  padding: 1rem 1.7rem;
  margin: 1.5rem 0rem;
}
.bg-grey {
  background-color: $color-gray-600;
}
.text-grey {
  color: $color-gray-500;
}
.text-darkgray {
  color: $color-gray-700;
}
.bg-lightgrey {
  background-color: $color-gray-800;
}
.bg-darkBlue {
  background-color: $color-blue-100;
}
.grey-container {
  background-color: $color-gray-800;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
}
.btn-green {
  background-color: $color-blue-300;
  color: white;
  border-radius: 15px;
  border: unset;
  text-transform: capitalize;
}
.btn-green:hover {
  background-color: $color-blue-200 !important;
}

.scrollable {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.screen {
  padding: 1.75rem;
  overflow-y: auto;
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.status {
  color: $color-white-true;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
  padding: 2px 5px;
  border-radius: 5px;

  &--completed,
  &--closed {
    background: $color-success-500;
  }

  &--cancelled {
    background: $color-attention-500;
  }

  &--pending,
  &--created {
    background: $color-yellow-100;
  }
}
.active {
  background-color: $color-blue-100;
}
.modal-header .btn-close {
  margin: unset !important;
}
.img-thumbnail {
  padding: 0;
}
.button-transparent {
  background: unset;
  border: unset;
  padding: unset;
}
.custom-shadow {
  box-shadow: 0 5px 6px 0 #00000029;
}

button:disabled,
button[disabled],
button:disabled:hover,
button[disabled]:hover {
  background-color: $color-grey-1900 !important;
  color: $color-grey-1000;
  cursor: unset;
}
.d-mobile {
  display: none !important;
}
.text-primary {
  color: $color-primary !important;
}
.text-secondary {
  color: $color-secondary !important;
}
.bg-orange {
  background-color: $color-orange-100 !important;
}
.bg-secondary {
  background-color: $color-secondary !important;
}
.bg-error {
  background-color: $color-tomato-100 !important;
}
.text-orange {
  color: $color-orange-100;
}
.text-error {
  color: $color-tomato-100 !important;
}
//this class is to be applied on any link that has no underline
.link-no-underline {
  text-decoration: underline !important;
  text-decoration-color: transparent !important;
  text-underline-offset: 4px;
}
//this class is to be applied on any link that has underline
.link-blue-underline {
  text-decoration: underline !important;
  text-decoration-color: $color-blue-600 !important;
  text-underline-offset: 4px;
}
//this class is for any transition on any link ( for both removing underline and adding underline and changing the color of the link)
.link-transition {
  -webkit-transition: color 0.2s ease-out, text-decoration-color 0.2s ease-out;
  -moz-transition: color 0.2s ease-out, text-decoration-color.2s ease-out;
  -ms-transition: color 0.2s ease-out, text-decoration-color 0.2s ease-out;
  -o-transition: color 0.2s ease-out, text-decoration-color 0.2s ease-out;
  transition: color 0.2s ease-out, text-decoration-color 0.2s ease-out;
}

.auth-paper {
  background-color: white;
  border-radius: 11px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

@media only screen and (min-width: 861px) and (max-width: 1024px) {
  .auth-paper {
    padding: 30px !important;
    max-width: 443px;
  }
}

@media only screen and (max-width: 860px) {
  .auth-paper {
    order: 1;
    max-width: 520px;
  }
}

@media only screen and (max-width: 559px) {
  .auth-paper {
    order: 1;
    max-width: 100%;
    padding: 30px 13px !important;
  }
}

@media only screen and(max-width : 860px) {
  .d-web {
    display: none !important;
  }
  .d-mobile {
    display: block !important;
  }
}

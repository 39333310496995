html,
body[dir="ltr"] {
  height: 100%;
  transition: 0.5s;
  font-family: "Montserrat";
  h1,h2,h3,h4,h5,h6,button,p,li{
    font-family: 'Montserrat';
  }
}

body[dir="rtl"] {
  height: 100%;
  transition: 0.5s;
  font-family: "IBMPlexSansArabic";
  h1,h2,h3,h4,h5,h6,button,p,li{
    font-family: 'IBMPlexSansArabic';
  }
}


fieldset,
legend {
  margin-bottom: 8px;
}

caption,
th {
  text-align: initial;
}

th,
td {
  padding: 8px 12px;
}

/* import vendro styles and packages */
@import "./app/scss/import";
@import "./assets/icomoon/icomoon.scss";

/* import atoms */
@import "./components/atoms/button/button.style";
@import "./components/atoms/errorMessage/error-message.style";
@import "./components/atoms/pagination/pagination.style.scss";
@import "./components/atoms/collapsible/collapsible.style.scss";
@import "./components/atoms/radioGroup/radio-group.style.scss";
@import "./components/atoms/checkboxGroup/check-group.style.scss";
@import "./components/atoms/select/select.style.scss";
@import "./components/atoms/toolbar/toolbar.style.scss";
@import "./components/atoms/fileUploader/file-uploader.style.scss";
@import "./components/atoms/timeline/timeline.style.scss";
@import "./components/atoms/spinner/spinner.style.scss";
@import "./components/atoms/customSwitch/switch.style.scss";
@import "./components/atoms/passwordStrengthBar/password-strength-bar.style.scss";
@import "./components/atoms/inputOtp/input-otp.style.scss";
@import "./components/atoms/feedbackImg/feedback-styles.scss";

/* import molecules */
@import "./components/molecules/textField/text-field.style";
@import "./components/molecules/searchbar/searchbar.style.scss";
@import "./components/molecules/sidebar/sidebar.style.scss";
@import "./components/molecules/notFoundFooter/notFoundFooter.scss";
@import "./components/molecules/slogan/slogan.scss";
@import "./components/molecules/info/info.style.scss";
@import "./components/molecules/languageSwitcher/language-swicher.style.scss";


/* import organisms */
@import "./components/pages/login/loginForm/loginForm.style.scss";
@import "./components/organisms/layout/layout.scss";
@import "./components/organisms/tabs/tabs.style.scss";
@import "./components/organisms/authLayout/index.styles.scss";
@import "./components/organisms/authLayout/footer/authFooter.scss";
@import "./components/organisms/authLayout/header/authHeader.scss";
@import "./components/organisms/slogansAndTitle/slogansAndTitle.scss";
@import "./components/organisms/otpForm/otp.style.scss";
@import "./components/organisms/registrationForm/registration-from.style.scss";

/* import pages */
@import "./components/pages/inquiries/conversationList/conversation-list.style.scss";
@import "./components/pages/inquiries/messagesList/messages-list.style.scss";
@import "./components/pages/inquiries/inputToolbar/input-toolbar.style.scss";
@import "./components/pages/inquiries/message/message.scss";
@import "./components/pages/inquiries/inquiries.style.scss";
@import "./components/pages/transactions/userWallet/user-wallet.style.scss";
@import "./components/pages/transactions/transactionItem/transaction-item.style.scss";
@import "./components/pages/home/singleTab/tab.style.scss";
@import "./components/pages/home/posts/actions/modalContentDiscount/discount.style.scss";
@import "./components/pages/home/posts/actions/modalContentPromotion/promotion.style.scss";
@import "./components/pages/orderDetails/order-details.style.scss";
@import "./components/pages/orderDetails/orderInfo/order-info.style.scss";
@import "./components/pages/orderDetails/orderItem/order-item.style.scss";
@import "./components/pages/home/posts/actions/modalContenShare/modal-content-share.style.scss";
@import "./components/pages/orderDetails/order-details.style.scss";
@import "./components/pages/orderDetails/orderInfo/order-info.style.scss";
@import "./components/pages/orderDetails/orderItem/order-item.style.scss";
@import "./components/pages/notFound/notFound.style.scss";
@import "./components/pages/bulkUploads/bulk-upload.style.scss";


/* import templates */
@import "./components/templates/authWrapper/auth-wrapper.style.scss";

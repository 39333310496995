.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &__container {
        font-size:$fs-16;
        padding: 10px;
        margin: 1px 0px;
        background: $color-gray-800;
        padding: 10px 15px;
        border-radius: 20px;
        max-width: 75%;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        align-self: flex-start;

        img {
            width: 90%;
            object-fit: contain;
        }
    }

    &__date {
        color: $color-gray-400;
        margin-bottom: 5px;
        font-size:$fs-12;
    }

    &--mine {
        .message__container {
            justify-content: flex-end;
            background: $color-blue-400;
            color: $color-white-true;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            align-self: flex-end;
        }

        .message__date {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color:$color-gray-600;

            span {
                &:first-of-type {
                    margin-right: 10px;
                }
            }
        }
    }
}

.paper-reg-padding {
  padding: 30px;
}
.registration-form {
  max-width: 470px;
  &__header {
    .info-reg {
      .info__title {
        font-size: $fs-32;
        font-weight: bold;
        color: $color-black-100;
        margin-bottom: 30px;
        line-height: 1.5;
        text-align: center;
      }
    }
  }

  form {
    width: 100%;
    .submission-success {
      color: $color-green-100;
    }
    .submission-error {
      color: $color-tomato-100;
    }
    .invitation-field {
      margin: 5px 0px 22px;
    }
    .registered-business {
      margin: 0px 0px 30px;
    }
    .recaptcha-reg {
      margin: 30px calc((100% - 304px) / 2);
    }
    .submit-button {
      button {
        text-transform: capitalize !important;
        font-size: $fs-13;
        padding-top: 14px;
        padding-bottom: 14px;
        font-weight: 600;
      }
    }
  }
  .text-field {
    width: 100%;
    .optional-label {
      font-size: 10px;
      font-weight: 600;
      padding: 0px 16px 7px 12px;
      color: rgba(9, 31, 31, 0.7);
      text-transform: capitalize;
    }
  }
  .text-field:last-child {
    margin-bottom: 25px !important;
  }
  &__footer {
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
    &__have-account {
      font-weight: 600;
      line-height: 1.3;
      font-size: $fs-14 !important;
      margin-bottom: 20px;
      &__signin {
        margin-right: 5px;
        color: $color-blue-800;
        font-weight: 600;
      }

      &__signin:hover {
        color: $color-blue-700;
        text-decoration-color: $color-blue-700 !important;
      }
    }

    &__agree-on-terms {
      line-height: 1.5;
      font-size: 12px;
      .privacy-policy,
      .terms-of-use {
        text-transform: lowercase;
        white-space: nowrap;
      }
      .privacy-policy:hover,
      .terms-of-use:hover {
        color: $color-blue-600 !important;
        text-decoration-color: $color-blue-600 !important;
      }
    }
  }

  .icon {
    font-size: $fs-14;
    color: $color-gray-200;
    margin-left: 4px;
  }
}

.endLabel-icon {
  font-size: $fs-21 !important;
}

@media only screen and (min-width: 861px) and (max-width: 1024px) {
  .registration-form {
    max-width: 100%;
  }
}

body[dir="rtl"] {
  .registration-form {
    .icon {
      margin-left: 0px;
      margin-right: 4px;
    }
  }
}

.strength-bar{
    display: flex;
    position: absolute;
    bottom: 5px;
    top:48px;
    width: 100%;
    &__list{
      width: 31%;
      height: 3px;
      background-color:$color-grey-1200;
      margin-right: 2px;
    }
  }
  
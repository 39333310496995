.info{
    &__title{
        font-size: $fs-32;
        font-weight: bold;
        color: $color-black-100;
        margin-bottom: 23px;
        font-weight:700;
    }
    &__description{
        font-size: $fs-16;
        color: $color-gray-100;
        margin-bottom: 29px !important;
        font-weight:500;
    }
}

@media only screen and (max-width: 560px){
    .info{
       &__title {
           text-align: center;
            font-size: $fs-24;
            margin-bottom: 11px;
        }
        &__description{
            text-align: center;
            font-size: $fs-14;
        }
    }
}
.paper-otp-padding{
    padding: 20px 50px 30px;
}
.otp-form{
    text-align: -webkit-center;
    &__header{
        .info-otp{
            .info__title{
                font-size: $fs-24;
                font-weight: bold;
                color: $color-black-100;
                margin-bottom: 20px;
                font-weight:700;
            } 
            .info__description{
                font-size: $fs-16;
                font-weight: 500;
                line-height: 1.5;
            }
        }
    }
        form{
        width: 100%;
    }
    .text-field{
        width: 100%;
    }
    &__footer{
        margin-top: 19px;
    }
    .icon{
        font-size: $fs-14;
        color : $color-gray-200;
    }
    .back-otp{
        font-size: $fs-14;
        font-weight:600;
        margin-top: 20px;
        color: $color-secondary;
        text-transform: capitalize;
        line-height: 2.14;
    }
    .back-otp:hover{
        color: $color-blue-600;
        text-decoration-color:$color-blue-600 !important;
      }
}
.otp-mobile{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &__text{
        font-size: $fs-18;
        font-weight: bold;
        color:$color-black-100;
    }
    &__button{
        color:$color-blue-600;
        font-size: $fs-12;
        margin: 2px 10px;
        font-weight:600;
    }
    &__button:hover{
        color: $color-blue-700;
        text-decoration-color:transparent !important;
      }
}

.counter{
    display: flex;
    font-size: $fs-14;
    margin-bottom: 15px;
    align-items: baseline;
    justify-content: center;
    font-weight:600;
    color: rgba(9, 31, 31, 0.7);
    &__text{
        color: rgba(9, 31, 31, 0.7);
    }
    &__resend-container{
        margin: 0 5px;
    }
    &__resend{
        color: rgba(9, 31, 31, 0.7);
    }
    &__number{
        color: #0f3333;
    }
    &__button{
        color: $color-secondary;
        margin: 0 5px;
    }
    &__button:hover{
        color: $color-blue-600;
        text-decoration-color:$color-blue-600 !important;
      }
}
.verify-otp{
    button{
        width: 370px;
        font-size: $fs-13;
        padding-top: 14px;
        padding-bottom: 14px;
        font-weight:600;
    }
}

@media only screen and  (min-width: 561px) and  (max-width: 860px){
    .otp-form{
        &__main-logo{
            &__upper-img{
                left: 162px;
            }
        }
    }
}
@media only screen and (max-width : 560px){
    .otp-form{
        &__main-logo{
            &__upper-img{
                left: 135px;
            }
        }
    }
}


@media only screen and (max-width: 449px){
    .verify-otp{
        button{
            width: 300px;
        }
    }
    .otp-form{
        &__main-logo{
            &__upper-img{
                left: 120px;
            }
        }
    }
}


body[dir='rtl']{
    .otp-form{
        &__main-logo{
            &__upper-img{
                position: absolute;
                left: 145px;
            }
    
        }
    .otp-mobile{
        &__text{
        direction: ltr;
        }
    }
}
@media only screen and (max-width : 560px){
    .otp-form{
        &__main-logo{
            &__upper-img{
                left: 135px;
            }
        }
    }
}

@media only screen and (max-width : 449px){
    .otp-form{
        &__main-logo{
            &__upper-img{
                left: 120px;
            }
        }
    }
}

}
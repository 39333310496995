.order-info {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.order-info__item {
    display: table-row;
    width: 100%;
    font-size: $fs-l;
    border-bottom: solid 1px $color-gray-600;
}

.order-info__item__cell {
    display: table-cell;
    padding: 10px 0;

    &--indicator {
        width: 150px;
        font-weight: 500;
    }
}

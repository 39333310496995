.copy-link__form {
    position: relative;
    width: 100%;
}

.copy-link__input {
    display: block;
    width: 100%;
    border: 3px solid;
    outline: 0;
    background: #fff;
    font-size:$fs-16;
    padding: 12px;
    margin-bottom: 20px;
}

.copy-link__button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: $fs-l;
    cursor: pointer;
    padding: 0;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background: $color-secondary;
    color: $color-white-true;

    &--success {
        background: $color-success-500;
    }
    
}

.promotion-container {
  &__item {
    background-color: transparent;
    color: $color-black-true;
    border: transparent !important;
    border-radius: 2px;
    border-radius: 15px;
    box-shadow: 0px 7px 7px rgb(195, 195, 195);
    margin: 0 7px;
    padding: 1.5rem 1rem;
    display: inline-block;
    &__price {
      font-size: 36px;
      font-weight: bold;
    }
  }
  &__selected {
    background-color: $color-green-600;
    color: white;

    .text-black{
      color: white !important;
    }
    .promotion-container__item__price{
      color: $color-blue-400;
    }
  }

  button:disabled,
  button[disabled]{
    background-color: #cccccc;
    color: $color-gray-900;

    .text-black{
      color: $color-gray-800 !important;
    }
    .text-grey{
      color: $color-gray-500 !important;
    }
  }
}


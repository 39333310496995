.collapsible {
  align-items: flex-start;
  text-transform: capitalize;
}

.collapsible__label {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: bold;
  margin-bottom: 10px;
  color: #05111d;
  cursor: pointer;
}

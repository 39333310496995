.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 20px;
}

.timeline-steps .timeline-content {
    width: 110px;
    text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 5px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        display: inline-block;
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 20px;
        opacity: 0.5;
    }

    &--complete {
        background: $color-blue-400;

        &:before {
            background: $color-blue-400;
        }
    }

    &--pending {
        background: $color-gray-700;

        &:before {
            background: $color-gray-700;
        }
    }

    &--warning {
        background: $color-yellow-100;

        &:before {
            background: $color-yellow-100;
        }
    }

    &--error {
        background: $color-attention-500;

        &:before {
            background: $color-attention-500;
        }
    }
}

@media (min-width: 768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: '';
        display: block;
        border-top: 0.25rem dotted $color-gray-700;
        width: 3.46rem;
        position: absolute;
        left: 7.5rem;
        top: 0.3125rem;
    }
    .timeline-steps .timeline-step:not(:first-child):before {
        content: '';
        display: block;
        border-top: 0.25rem dotted $color-gray-700;
        width: 3.8125rem;
        position: absolute;
        right: 7.5rem;
        top: 0.3125rem;
    }
}

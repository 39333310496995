.bulk-uploads {
  padding: 0.5rem;
  &__title {
    margin-bottom: 1.75rem;
  }
  &__content {
    margin-top: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 1.75rem 0;
  }
  &__line-bottom {
    margin-bottom: 1rem;
    border-bottom: 1px solid lightgrey; 

  }
  &__download {
    margin-top: 0.75rem;
  }
  &__link {
    font-size: 1rem;
    margin: auto;
    width: 50%;
    text-align: center;
  }
  &__upload-file {
    margin: 1.75rem 0;
    padding: 0 10px;
    &__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
    }
    &__input , &__submit{
        margin-top: 0.75rem;
    }
  }
}

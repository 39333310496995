.feedback-Img{
    margin-bottom: 20px;
    &__light-overlay{
        position: relative;
        width: fit-content;
        img{
            width: 148.6px;
            height: 116.8px;
        }
    }
    .top-img{
        position: absolute;
        top: 18px;
        left: 20%;
        img{
            width: 91.1px;
            height: 91.1px;
        }
    }
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .notFound-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px;
    margin: 5rem 1rem;
    img {
      max-width: 510.8px;
      width: 100%;
    }
    .notFound-text {
      display: flex;
      margin-top: 1rem;
      flex-direction: column;
      text-align: justify;
      h2 {
        font-size: 60px;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: -0.45px;
        color: #0079b1;
      }
      h3 {
        font-size: 32px;
        font-weight: 600;
        line-height: 0.97;
        letter-spacing: -0.24px;
        color: #0f3333;
      }
     &__sorry {
        max-width: 373px;
        opacity: 0.87;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.94;
        letter-spacing: -0.12px;
        color: #0f3333;
      }
      .home-button {
        border-radius: 15px;
      }
      .contact-button {
        border-radius: 15px;
        color: #00b1b8;
        border: solid 1px #00b1b8 !important;
        background-color: #fff !important;
      }
      .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        button {
          padding: 0.7rem 2.3rem;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .notFound-text {
    text-align: center !important;
    width: 100%;
    &__sorry {
      width: 100%;
      max-width: none !important;
    }
  }
  .notFound-img {
    width: 100%;
  }
  .buttons-wrapper {
    display: block !important;
    button {
      margin: 0 0.5rem;
    }
  }
}

@media only screen and (max-width: 481px) {
  .notFound-text {
    text-align: center !important;
    margin-top: 0rem;
    width: 100%;
  }
  .notFound-content {
    gap: 9px !important;
    padding-bottom: 6rem !important;
  }

  .notFound-img {
    width: 100%;
  }
  h2 {
    font-size: 65px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  &__sorry {
    font-size: 18px !important;
  }
  .home-button,
  .contact-button {
    width: 100%;
  }
  .buttons-wrapper {
    gap: 6px !important;
    display: flex !important;
  }
  .page-wrapper p {
    font-size: 14px !important;
  }
}

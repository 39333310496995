/**
 * Please file all font-size values in here
 */

$fs-16-px: 16px;
$fs-16-px: 16px;
$fs-xxxs: 0.6 * $fs-16-px; /* 9px */
$fs-10: 0.63 * $fs-16-px; /* 10px */
$fs-11: 0.6875 * $fs-16-px; /* 11px */
$fs-12: 0.75 * $fs-16-px; /* 12px */
$fs-13: 0.8125 * $fs-16-px; /* 13px */
$fs-s: 0.85 * $fs-16-px; /* 13.5px */
$fs-14: 0.875 * $fs-16-px; /* 14px */
$fs-16: 1 * $fs-16-px; /* 16px */
$fs-18: 1.125 * $fs-16-px; /* 18px */
$fs-20: 1.25 * $fs-16-px; /* 20px */
$fs-21: 1.3125 * $fs-16-px; /* 21px */
$fs-24: 1.5 * $fs-16-px; /* 24px */
$fs-28: 1.75 * $fs-16-px; /* 28px */
$fs-32: 2 * $fs-16-px; /* 32px */
$fs-48: 3 * $fs-16-px; /* 48px */
$fs-l: 1.25 * $fs-16-px; /* 20px */
$fs-xl: 1.56 * $fs-16-px; /* 25px */
$fs-xxl: 2.5 * $fs-16-px; /* 40px */
$fs-xxxl: 3.6 * $fs-16-px; /* 54px */
$fs-xxxxl: 3.6 * $fs-16-px; /* 57px */

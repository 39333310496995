/**
 * A more sensible default box sizing:
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

 html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

.rsw_2Y {
  z-index: 0 !important;
}
.auth-wrapper {
  display: flex;
  width: 100vw;
  transition: left 0.5s;
  padding: 0 375px;
  gap: 70px;
  div:only-child {
    margin: auto;
  }
}
@media only screen and (max-width: 1870px) {
  .auth-wrapper {
    padding: 0px 320px 30px 320px;
  }
}
@media only screen and (max-width: 1770px) {
  .auth-wrapper {
    padding: 0px 250px 30px 250px;
  }
}
@media only screen and (max-width: 1700px) {
  .auth-wrapper {
    padding: 0 200px 0 200px;
  }
}
@media only screen and (max-width: 1600px) {
  .auth-wrapper {
    padding: 0 150px 0 150px;
  }
}
@media only screen and (max-width: 1500px) {
  .auth-wrapper {
    padding: 0 120px 0 120px;
  }
}

@media only screen and (max-width: 1439px) {
  .auth-wrapper {
    padding: 0 80px 0 80px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1240px) {
  .auth-wrapper {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 861px) and (max-width: 1024px) {
  .auth-wrapper {
    padding: 0 20px 0 20px;
  }
}

@media only screen and (max-width: 860px) {
  .auth-wrapper {
    flex-direction: column;
    padding: 0 20px 0 20px;
    align-items: center;
    &__title-with-slogans {
      order: 2;
    }
  }
}

@media only screen and (max-width: 559px) {
  .auth-wrapper {
    flex-direction: column;
    padding: 0 20px 0 20px;
    align-items: center;
    &__title-with-slogans {
      order: 2;
    }
  }
}

@media only screen and (max-width: 414px) {
  .auth-wrapper {
    padding: 0 10px 0 10px !important;
  }
}

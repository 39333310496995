.transaction-item {
    width: 100%;
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background: linear-gradient(
        67deg,
        $color-gray-800 0%,
        $color-white-true 100%
    );

    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.transaction-item__date {
    flex: 1;

    &__box {
        background-color: $color-white-true;
        flex-grow: 0;
        border-radius: 5px;
        width: 60px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: solid 1px $color-gray-500;

        &__day {
            font-size: $fs-s;
            color: $color-gray-200;
            font-weight: 500;
        }

        &__year {
            font-size: $fs-s;
            color: $color-gray-400;
        }
    }
}

.transaction-item__info {
    flex: 3;
    text-align: left;

    &__title {
        font-size: $fs-l;
        color: $color-gray-200;
        font-weight: 500;
    }

    &__subtitle {
        font-size: $fs-s;
        color: $color-gray-400;
    }
}

.transaction-item__value {
    flex: 3;
    margin-left: 50px;
    font-weight: 400;
    text-align: end;
    padding-right: 20%;
    font-size: $fs-l;

    &--positive {
        color: $color-success-500;
    }

    &--negative {
        color: $color-attention-500;
    }

    span {
        margin-left: 2px;
    }
}

.transaction-item__status {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.language-swicher {
  &__title {
    align-items: center;
    color : $color-black-100
  }
  .dropdown-toggle{
    min-width: 21px;
  }
  .dropdown-item:active{
    color: $color-black-100;
  }
  .dropdown-toggle::after{
    color: $color-grey-1100;
    opacity: 1 !important;
  }
  .dropdown-menu{
    min-width : 100% !important;
  }
}
@media only screen and(max-width : 860px) {
    .dropdown-toggle{
      padding: 0.5rem 0;
      font-size: $fs-16;
    }
    body[dir='rtl']{
      .dropdown-toggle::after {
      margin-right: 4px;
      }
      .dropdown-toggle{
        padding:0;
        font-size: $fs-16;
      }
    }
  }

.conversation-list {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 97px);
    overflow-y: auto;
}

.conversation-list__item {
    display: flex;
    align-items: center;
    padding: 10px;

    &:hover {
        background: $color-gray-600;
        cursor: pointer;
    }
}

.conversation-list__item__photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.conversation-list__item__title {
    font-size: $fs-16;
    font-weight: bold;
    margin: 0;
}

.conversation-list__item__snippet {
    font-size: 14px;
    color: #888;
    margin: 0;
}

.header {
    height: $header-height;
    .nav-link{
        color : white !important;
    }
}
.footer{
    &__socialMedia-icon{
        font-size: 20px;
        margin: 0.1rem;
        color : $color-blue-200
    }
}
.input-toolbar {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: $color-gray-800;
    width: calc(100% - 380px);
    border-top: solid 1px $color-gray-500;
    height: fit-content;
    width: 100%;
}

.input-toolbar__items {
    display: flex;
    align-items: center;
    width: 100%;
}

.input-toolbar__side-items {
    padding: 0 10px;
}

.input-toolbar__text-field {
    flex-grow: 1;
    font-size: 14px;
    height: 40px;
    align-self: stretch;
    border: 1px solid $color-gray-500;
    border-radius: 5px;
    text-indent: 7px;

    &::placeholder {
        opacity: 0.3;
    }

    &:active,
    &:focus {
        outline: none;
    }
}

.input-toolbar__send-btn {
    border: none;
    background: none;

    &:disabled {
        opacity: 0.5;
    }
}


.order-info {
    display: table;
    width: fit-content;
    padding-right: 20px;
    border-collapse: collapse;
}

.order-info__item {
    display: table-row;
    font-size: $fs-l;
    border-bottom: solid 1px $color-gray-600;
}

.order-info__item__cell {
    display: table-cell;
    padding-bottom: 12px;

    &--indicator {
        width: 150px;
        font-weight: 500;
    }
}

.user-wallet {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    height: 100px;
    color: $color-white-true;
    padding: 20px 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background: linear-gradient(
        67deg,
        $color-primary 0%,
        $color-secondary 100%
    );
}

.user-wallet__summary__text {
    text-align: center;
    font-size: fs-l;
}

.user-wallet__summary__balance {
    text-align: center;
    font-size: $fs-l;
    letter-spacing: 1.5px;
    font-weight: 500;
}

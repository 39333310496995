/**
 * Please file all colors here
 * and sort them correctly by value,
 * prefer using steps of 100 for color gradients
 */

/* Pure colors. */
$color-black-true: #000;
$color-white-true: #fff;

$color-black-100: #091f1f;

$color-gray-100: rgba(9, 31, 31, 0.7);
$color-gray-200: #222;
$color-gray-300: #333;
$color-gray-400: #707070;
$color-gray-700: #909090;
$color-gray-500: #dddddd;
$color-gray-600: #e3e7e8;
$color-gray-800: #f3f4f5;
$color-gray-900: #f6f7f8;
$color-grey-1000: rgba(9, 31, 31, 0.2);
$color-grey-1100: #b1b1b1;
$color-grey-1200: #e2e2e2;
$color-grey-1300: #091f1f94;
$color-grey-1400: #091f1fde;
$color-grey-1500: rgba(9, 31, 31, 0.3);
$color-grey-1600: rgba(255, 255, 255, 0.7);
$color-grey-1700: rgba(255, 255, 255, 0.8);
$color-grey-1800: #eef5f9;
$color-grey-1900: #e5e7e7;
$color-grey-2000: rgba(0, 0, 0, 0.87);

$color-white-true: #fff;

$color-yellow-100: #ffcc00;

$color-orange-100: #ffab00;

$color-blue-100: #123f87;
$color-blue-200: #42bbc2;
$color-blue-300: #1bb0b8;
$color-blue-400: #054285;
$color-blue-500: #f7f7f7;
$color-blue-600: #0078b2;
$color-blue-700: #00b0b8;
$color-blue-800: #00aeb6;
$color-blue-900: #d0e9eb;
$color-blue-1000: #9cdade;

// @TODO : change blue 200 & 300 to be green

$color-green-100: #36b37e;
$color-green-300: #28c969;
$color-green-600: #10393b;

$color-tomato-100: #ff5630;

$color-attention-500: #cd3c14;
$color-success-500: #32c832;

$color-violet-100: #802080;

/* Theme colors. */
$color-primary: $color-blue-100;
$color-secondary: $color-blue-300;

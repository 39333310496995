.paper-login-padding {
  padding: 30px;
  height: fit-content;
}
.login-form {
  width: 470px;
  &__header {
    text-align: center;
    h3 {
      margin-bottom: 30px;
      font-size: $fs-32;
      color: $color-black-100;
      font-weight: bold;
      line-height: 1.5;
    }
  }

  form {
    width: 100%;
    .submit-button {
      margin-bottom: 20px;
      button {
        background-color: $color-blue-800;
        font-size: $fs-12;
        font-weight: 600;
      }
      button:hover {
        transition: 200ms;
      }
    }
  }
  .text-field {
    width: 100%;
  }
  &__footer {
    font-weight: 500;
    text-align: center;
    .privacy-policy {
      text-transform: lowercase;
    }
    &__need-account {
      font-weight: 600;
      line-height: 1.3;
      font-size: $fs-14 !important;
      color: $color-grey-2000;
    }
    &__signup {
      color: $color-blue-800;
      font-weight: 600;
    }

    &__signup:hover {
      color: $color-blue-700;
      text-decoration-color: $color-blue-700 !important;
    }
    .terms-of-use {
      text-transform: lowercase;
    }
  }
}

@media only screen and (min-width: 861px) and (max-width: 1024px) {
  .login-form {
    max-width: 100%;
  }
}

@media only screen and (min-width: 560px) and (max-width: 860px) {
  .login-form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 559px) {
  .login-form {
    max-width: 100%;
  }
}

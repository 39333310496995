.order-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    padding: 15px;
    background: transparent;
    border: solid 1px $color-gray-600;
    border-radius: 5px;
}

.order-item__info {
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.order-item__info__img {
    height: 150px;
    width: auto;
}

.order-item__info__data {
    width: 500px;
    display: flex;
    flex-direction: column;
}

.order-item__info__data__item {
    margin-bottom: 7px;
}

.order-item__status {
    // border: solid 1px $color-gray-600;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 15px;
    font-weight: 500;
    border-radius: 5px;
    color: $color-white-true;

    &--red {
        background: $color-attention-500;
    }

    &--yellow {
        background: $color-yellow-100;
    }

    &--blue {
        background: $color-blue-100;
    }

    &--green {
        background: $color-green-300;
    }
}

.order-item__title {
    font-weight: 500;
}

.order-item__info__timeline {
    width: 100%;
}

.slogans-title {
  display: flex;
  flex-direction: column;
  &__big-title {
    margin-bottom: 40px;
    color: $color-blue-900;
    max-width: 700px;
    font-weight: bold;
    line-height: 1.25;
    font-size: $fs-48;
  }
  .d-tablet {
    display: none;
  }
  &__web-mobile-views {
    display: block;
  }
}

@media only screen and(max-width : 1240px) {
  .slogans-title {
    &__big-title {
      font-size: 38px;
    }
  }
}

@media only screen and(min-width : 861px) and (max-width: 1024) {
  .slogans-title {
    &__big-title {
      width: 100%;
      font-size: 30px;
    }
  }
}

@media only screen and(max-width : 860px) {
  .slogans-title {
    margin-top: 60px;
    align-items: center;
    text-align: center;
    &__big-title {
      color: $color-blue-1000;
      font-size: $fs-32;
    }
    .d-tablet {
      display: block !important;
    }
    &__web-mobile-views {
      display: none !important;
    }
    &__slogans-tablets {
      &__horizontal-slogans {
        display: flex;
        flex-direction: row;
      }
    }
    &__big-title {
      font-weight: bold;
      line-height: 1.33;
      max-width: 520px;
      margin-bottom: 29px;
    }
  }
}

@media only screen and(max-width : 559px) {
  .slogans-title {
    margin-top: 60px;
    align-items: center;
    text-align: center;
    .d-tablet {
      display: none !important;
    }
    &__web-mobile-views {
      display: block !important;
    }
    &__big-title {
      font-weight: bold;
      line-height: 1.5;
      max-width: unset;
      margin-bottom: 29px;
      font-size: $fs-32;
    }
  }
}

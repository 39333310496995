.messages-list {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.messages-list__container {
    height: calc(100vh - 197px);
    overflow-y: auto;
    position: relative;
    padding-top: 10px;
}

.messages-list__display {
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: $color-gray-800;

    &__item {
        position: relative;
        margin-right: 8px;

        img {
            width: 200px;
            height: auto;
        }

        &__close {
            right: 5px;
            top: 5px;
            position: absolute;
            border: none;
            background: none;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            background: rgba($color: $color-white-true, $alpha: 0.2);

            :hover {
                opacity: 0.8;
            }
        }
    }
}
